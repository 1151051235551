import { Component, OnDestroy, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ModalSizes, ModalAction } from 'app/shared/modals/modal.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { environment } from 'environments/environment';
import { SharedService } from 'app/shared/shared.service';
import { PhoneValidationPipe } from 'app/shared/pipes/phone-validation.pipe';
import {
  AddressBookEntry,
  AvailableBucketsResultEnum,
  NotificationTypeEnum,
  Shipment,
  VisitTypeEnum,
} from 'app/shared/shared.interfaces';
import { ManageBucketService } from '../manage-bucket.service';
import { ParcelSizes } from 'app/interfaces/parcelSize.interfaces';
import { ModifyBucketIntentionEnum, ModifyBucketModalActionsEnum } from './modify-bucket-order.interfaces';
import { BucketDetails } from 'app/shared/buckets/bucket.interfaces';
import { GetOrderDetailsRequest } from '../buckets.interfaces';
import { ConsignmentService } from 'app/dashboard/settings/consignments/consignment.service';
import { ConsignmentConfiguration } from 'app/dashboard/settings/consignments/consignments.interface';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-modify-bucket-order',
  templateUrl: './modify-bucket-order.component.html',
  styleUrls: ['./modify-bucket-order.component.scss']
})
export class ModifyBucketOrderComponent extends SimpleModalComponent<any, any> implements OnInit, OnDestroy {
  public ModalSizes = ModalSizes;
  availableBuckets;
  public iconTypes = IconTypes;
  public uiColors = UiColors;
  public uiThemes = UiThemes;
  public buttonTypes = ButtonTypes;
  public justifyContent = JustifyContent;
  public actions: ModalAction[] = [
    { name: ModifyBucketModalActionsEnum.Cancel, title: 'Cancel' },
    { name: ModifyBucketModalActionsEnum.SaveAndPrint, title: 'Save And Print', isPrimary: true },
    { name: ModifyBucketModalActionsEnum.Confirm, title: 'Confirm', isPrimary: true },
  ];

  public loadingActions: ModalAction[] = [
    { name: ModifyBucketModalActionsEnum.Cancel, title: 'Cancel' },
    { name: ModifyBucketModalActionsEnum.Loading, title: 'Loading...', disabled: true },
  ];
  order_id: string;
  business_id: string;
  original_address: string;
  displayInvalid: boolean = false;
  intention: string;
  environment = environment;
  addressBookUsed: boolean = false;
  warehouse_id: string;
  addressBook_id: string;
  parcelSizes: string[] = Object.values(ParcelSizes);
  shipment: Shipment = {
    save: false,
    consignment: null,
    business_reference: null,
    visit_type: VisitTypeEnum.Delivery,
    contact_description: null,
    address: {
      address_line_1: '',
      suburb: null,
      building_or_complex_name: null,
      unit_or_floor: null,
      postal_code: null,
      formatted_address: null,
      geocoded_address: null,
      latitude: null,
      longitude: null,
      is_modified: false,
      country: null,
      country_short_code: null,
      client_address: null
    },
    contact: {
      customer_name: null,
      customer_phone: null,
      email_address: null,
      special_instructions: null,
      delivery_window_start: null,
      delivery_window_end: null,
      duration_at_stop_minutes: null,
      includeParcelAmounts: false,
      paymentsTotal: null,
    },
    parcels: [],
  };
  bucket_id: string;
  business_reference: string;
  bucket_details: BucketDetails;
  availableBucketResult: AvailableBucketsResultEnum;
  manual_entry_required: boolean = false;
  disableSave: boolean;
  consignmentDetails: ConsignmentConfiguration = { consignmentValues: [], useCustomConsignments: true };
  addresses: any[];
  private addressSearch$ = new Subject<string>()

  constructor(
    public notificationService: NotificationsService,
    private sharedService: SharedService,
    private manageBucketService: ManageBucketService,
    private phoneValidationPipe: PhoneValidationPipe,
    private consignmentService: ConsignmentService
  ) {
    super();
  }

  ngOnInit(): void {

    this.addressSearch$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((keyword: string) =>
        this.manageBucketService
          .searchAddressBook({ business_id: this.business_id, search_term: keyword })
          .then((response) => {
            this.addresses = response.data;
          })
      )).subscribe();


    if (this.environment.appVersion === 2) {
      if (this.order_id) {
        this.getOrderDetailsStaged();
        this.intention = ModifyBucketIntentionEnum.Edit;
        return;
      }
    }
    if (this.business_reference) {
      this.getOrderDetails();
      this.intention = ModifyBucketIntentionEnum.Edit;
    } else {
      this.intention = ModifyBucketIntentionEnum.Add;
    }

    this.consignmentService.getLiveBusinessConsignmentsConfiguration(this.business_id).subscribe((result) => {
      if (!result) {
        this.consignmentDetails = { consignmentValues: [], useCustomConsignments: true};
        return;
      }
      this.consignmentDetails = result;
    });


  }

  ngOnDestroy(): void {
    this.addressSearch$.unsubscribe();
  }

  getAddressesFromAddressBook(keyword: string): void {
    if(keyword.length > 2){
      this.addressSearch$.next(keyword)
    } else {
      this.addresses = [];
    }
  }

  checkValue(value) {
    if (value === '' || value === undefined || value === null) {
      return true;
    } else {
      return false;
    }
  }

  checkLength(value, length) {
    if (value.length < length) {
      return true;
    } else {
      return false;
    }
  }

  clearParcels() {
    this.shipment.parcels = [];
  }

  checkParcelsRefs() {
    const refs = [];
    const parcels: any = this.shipment.parcels;
    if (
      parcels.some((parcel) => {
        return !parcel.parcel_reference || parcel.parcel_reference === '';
      })
    ) {
      return true;
    }
    parcels.forEach((parcel) => {
      refs.push(parcel.parcel_reference);
    });
    const counts = [];
    for (let i = 0; i <= refs.length; i++) {
      if (counts[refs[i]] === undefined) {
        counts[refs[i]] = 1;
      } else {
        return true;
      }
    }
    return false;
  }
  // TODO: type = AddressBookEntry
  onAddressBookPlace($event: any): void {
    this.addressBookUsed = true;
    this.shipment.address.address_line_1 = $event.formatted_address;
    this.shipment.address['formatted_address'] = $event.formatted_address;
    this.shipment.address.building_or_complex_name = $event.complex;
    this.shipment.address.suburb = $event.suburb;
    this.shipment.address.postal_code = $event.postal_code;
    this.shipment.address.geocoded_address = $event.formatted_address;
    this.shipment.address.latitude = $event.latitude;
    this.shipment.address.longitude = $event.longitude;
    this.shipment.contact.customer_name = $event.name;
    this.shipment.contact.email_address = $event.email;
    this.shipment.contact.customer_phone = $event.phone;
    // $event can either return as an address book entry or a string (user typed until selection is made)
    this.shipment.contact_description = $event.description ? $event.description : ($event as unknown as string);
    this.shipment.address['country'] = $event.country;
    this.shipment.address['country_short_code'] = $event.country_short_code;
  }

  clearParcelValues() {
    this.shipment.parcels.forEach((parcel) => {
      parcel.parcel_value = null;
    });
    this.updatePaymentsTotal();
  }

  updatePaymentsTotal() {
    let paymentsTotal = 0;
    this.shipment.parcels.forEach((parcel) => {
      paymentsTotal = Number(paymentsTotal) + Number(parcel.parcel_value);
    });

    this.shipment.contact.paymentsTotal = this.convertDecimals(paymentsTotal);
  }

  convertDecimals(amount) {
    return (Math.round(amount * 100) / 100).toFixed(2);
  }

  checkParcels() {
    const parcels: any = this.shipment.parcels;
    let boolean = false;
    if (Object.keys(parcels).every((k) => parcels[k] === 0)) {
      boolean = true;
    }
    return boolean;
  }

  validate() {
    if (this.checkValue(this.shipment.contact.customer_name)) {
      this.alertError('Please add a Customer Name for this order');
    } else if (this.checkValue(this.shipment.address.geocoded_address) && this.intention === 'add') {
      this.alertError('Please select an address from the dropdown');
    } else if (this.checkValue(this.shipment.business_reference)) {
      this.alertError('Please supply a Business Reference for this order');
    } else if (!this.phoneValidationPipe.transform(this.shipment.contact.customer_phone)) {
      this.alertError('Please add a valid Phone number for this order');
    } else if (this.checkValue(this.shipment.contact.email_address)) {
      this.alertError('Please add an Email Address for this order');
    } else if (!this.validateEmail(this.shipment.contact.email_address)) {
      this.alertError('Please use a valid Email Address');
    } else if (this.checkParcels()) {
      this.alertError('Please add at lease 1 parcel to this order.');
    } else if (this.checkParcelsRefs()) {
      this.alertError('Please use unique reference numbers');
    } else if (this.shipment.contact.includeParcelAmounts && this.checkValues()) {
      this.alertError('Please include all parcel values');
    } else if (this.shipment.contact.special_instructions?.length > 250) {
      this.alertError('Special instructions character limit of 250');
    } else {
      return true;
    }
  }

  checkValues() {
    const missingValue = [];
    this.shipment.parcels.forEach((parcel) => {
      if (!parcel.parcel_value) {
        missingValue.push(parcel);
      }
    });
    if (missingValue.length) {
      return true;
    } else {
      return false;
    }
  }

  validateEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  alertError(message) {
    this.notificationService.publish({
      type: NotificationTypeEnum.Error,
      message: message,
    });
  }

  changeVisitType(visitType: VisitTypeEnum | string) {
    // this functions as a toggle between collection and deliver
    if (visitType === VisitTypeEnum.Collection) {
      this.shipment.visit_type = VisitTypeEnum.Delivery;
    } else {
      this.shipment.visit_type = VisitTypeEnum.Collection;
    }
  }

  onPlacesChange($event: google.maps.places.PlaceResult): void {
    this.addressBookUsed = false;
    this.shipment.address.address_line_1 = $event.formatted_address;
    this.shipment.address['geocoded_address'] = $event.formatted_address;
    this.shipment.address['formatted_address'] = $event.formatted_address;
    this.shipment.address.latitude = Number($event.geometry.location.lat().toFixed(5));
    this.shipment.address.longitude = Number($event.geometry.location.lng().toFixed(5));
    this.shipment.address.suburb = '';
    this.shipment.address.postal_code = '';
    this.manual_entry_required = false;
    if ($event.types.includes('point_of_interest' || 'establishment')) {
      this.shipment.address.building_or_complex_name = $event.name;
    }
    $event.address_components.forEach((component) => {
      if (component.types.includes('postal_code')) {
        // postalcode
        this.shipment.address['postal_code'] = component.short_name;
        this.shipment.address['geocoded_postal_code'] = component.short_name;
      }
      if (component.types.includes('sublocality')) {
        // suburb
        this.shipment.address['suburb'] = component.short_name;
        this.shipment.address['geocoded_suburb'] = component.short_name;
      }
      if (component.types.includes('country')) {
        // country
        this.shipment.address['country'] = component.long_name;
        this.shipment.address['country_short_code'] = component.short_name;
      }
    });

    if (!this.shipment.address.suburb || !this.shipment.address.postal_code) {
      this.manual_entry_required = true;
    }
  }

  addParcel(parcelSize: ParcelSizes | string): void {
    this.shipment.parcels.push({
      size: parcelSize,
      parcel_reference: this.shipment.business_reference + '.' + (this.shipment.parcels.length + 1),
      tracking_number: this.shipment.business_reference + '.' + (this.shipment.parcels.length + 1),
    });
  }

  bulkAddParcels(amount: string, parcelSize: ParcelSizes | string): void {
    if(isNaN(+amount)){
      return;
    }
    const length = this.shipment.parcels.length;
    const numberParcels = +amount;
    for (let index = length; index < length + numberParcels; index++) {
      this.shipment.parcels.push({
        size: parcelSize,
        parcel_reference: this.shipment.business_reference + '.' + (this.shipment.parcels.length + 1),
        tracking_number: this.shipment.business_reference + '.' + (this.shipment.parcels.length + 1),
      });
    }
  }

  removeParcel(index): void {
    this.shipment.parcels.splice(index, 1);
  }

  removeDate(field): void {
    this.shipment.contact[field] = null;
  }

  handleAction($event: string): void {
    switch ($event) {
      case ModifyBucketModalActionsEnum.Cancel:
        this.result = undefined;
        this.close();
        break;
      case ModifyBucketModalActionsEnum.Confirm:
        if (this.disableSave) {
          break;
        }

        if (this.validate()) {
          if (this.shipment.address.geocoded_address !== this.original_address) {
            this.shipment.address.is_modified = true;
          }
          this.result = {
            intention: this.intention,
            bucket_id: this.bucket_id ? this.bucket_id : null,
            warehouse_id: this.bucket_details?.warehouse_id ? this.bucket_details.warehouse_id : this.warehouse_id,
            shipments: [this.shipment],
            business_id: this.business_id,
          };
          if (this.shipment.save) {
            this.saveAddress();
          }

          this.addOrUpdateOrder();
        }
        break;
      case ModifyBucketModalActionsEnum.SaveAndPrint:
        if (this.validate()) {
          if (this.shipment.address.geocoded_address !== this.original_address) {
            this.shipment.address.is_modified = true;
          }
          this.result = {
            intention: this.intention,
            bucket_id: this.bucket_id ? this.bucket_id : null,
            warehouse_id: this.bucket_details?.warehouse_id ? this.bucket_details.warehouse_id : this.warehouse_id,
            shipments: [this.shipment],
            business_id: this.business_id,
            print: true,
          };

          this.addOrUpdateOrder();
        }
        break;
    }
  }

  addOrUpdateOrder(): void {
    this.disableSave = true;
    if (this.availableBucketResult === AvailableBucketsResultEnum.Create) {
      const createBucketDetail = {
        ...this.result,
        bucket_details: this.bucket_details,
      };

      this.manageBucketService.addOrderToNewBucket(createBucketDetail).then((response) => {
        if (this.result.print) {
          const waybills: string[][] = Object.values(response.bucket_ids);
          this.manageBucketService.printWaybill(waybills[0]);
        }

        if (response) {
          this.result.intention = ModifyBucketIntentionEnum.Add;
          this.close();
        } else {
          this.disableSave = false;
        }
      });
    } else if (this.bucket_id && !this.business_reference) {
      this.manageBucketService.addOrderToExistingBucket(this.result).then((response) => {
        if (this.result.print) {
          this.manageBucketService.printWaybill([response.validations[0].waybill_number]);
        }
        if (response) {
          this.result.intention = ModifyBucketIntentionEnum.Add;
          this.close();
        } else {
          this.disableSave = false;
        }
      });
    } else {
      this.disableSave = false;
      this.result.intention = ModifyBucketIntentionEnum.Edit;
      this.manageBucketService.updateOrder(this.result).then((response) => {
        if (this.result.print) {
          this.manageBucketService.printWaybill([response.validations[0].waybill_number]);
        }

        if (response) {
          this.result.intention = ModifyBucketIntentionEnum.Edit;
          this.close();
        } else {
          this.disableSave = false;
        }
      });
    }
  }

  saveAddress(): void {
    const addressBookData: AddressBookEntry = {
      description: this.shipment.contact_description ?? this.shipment.contact.customer_name,
      business_id: this.business_id,
      name: this.shipment.contact.customer_name,
      suburb: this.shipment.address.suburb,
      postal_code: this.shipment.address.postal_code,
      formatted_address: this.shipment.address.geocoded_address,
      phone: this.shipment.contact.customer_phone,
      email: this.shipment.contact.email_address,
      complex: this.shipment.address.building_or_complex_name,
      unit_no: this.shipment.address.unit_or_floor,
      country: this.shipment.address.country,
      country_short_code: this.shipment.address.country_short_code,
      latitude: this.shipment.address.latitude,
      longitude: this.shipment.address.longitude,
    };

    this.sharedService
      .setAddress(addressBookData)
      .then(() => {
        this.notificationService.publish({
          type: NotificationTypeEnum.Success,
          message: 'Contact Added',
        });
      })
      .catch((error) => {
        this.notificationService.publish({
          type: NotificationTypeEnum.Error,
          message: error.response?.data.message,
        });
      });
  }

  getOrderDetails(): void {
    const payload: GetOrderDetailsRequest = {
      bucket_id: this.bucket_id,
      business_reference: this.business_reference,
    };
    this.manageBucketService.getOrderDetails(payload).then((res) => {
      if (res.data.address) {
        this.original_address = res.data.address.geocoded_address;
      }
      this.shipment = res.data;
      if (this.shipment.parcels?.length && this.shipment.parcels[0]?.parcel_value) {
        this.shipment.contact.includeParcelAmounts = true;
      }
    });
  }

  getOrderDetailsStaged(): void {
    this.manageBucketService.getOrderDetailsStaged(this.order_id).then((res) => {
      if (res.data[0].address) {
        this.original_address = res.data[0].address.address_line_1;
      }
      this.shipment = res.data[0];
      this.shipment.address.geocoded_address = res.data[0].address.address_line_1;
      if (this.shipment.parcels?.length && this.shipment.parcels[0]?.parcel_value) {
        this.shipment.contact.includeParcelAmounts = true;
      }
    });
  }
}
