<div *ngIf="isMobile">
  <div class="mobile-banner">
    <div class="content">
      <h3>If you are a driver <br/> please return to the <br/>app to log in</h3>
      <svg-icon src="../../assets/img/phone-notifications.svg" class="icon"></svg-icon>
      <h4>Enterprise Login Unavailable<br/>on mobile</h4>
      <br/>
      <h4>Please use the<br/> desktop site</h4>
    </div>
  </div>
</div>

<div *ngIf="siteUnderMaintenance?.is_active">
  <div class="mobile-banner">
    <div class="content">
      <h3>{{ siteUnderMaintenance.title }}</h3>
      <svg-icon src="../../assets/img/process/delivery.svg" class="icon"></svg-icon>
      <h4>{{ siteUnderMaintenance.subtitle }}</h4>
    </div>
  </div>
</div>

<div *ngIf="!isMobile">
  <div class="background" id="particles-js"></div>

  <div class="banner" *ngIf="context != 'beta'"> 
    <!-- || context != 'africa2' || context != 'beta-staging' -->
    <app-slider-button 
      (click)="goHome()" 
      [iconType]="IconTypes.BackArrow" 
      [labelText]="'Home'"
      [iconColor]="UiColors.Blue" 
      [primaryColor]="UiColors.Grey7" 
      [secondaryColor]="UiColors.White">
    </app-slider-button>    
  </div>
  <div class="home-wrap">
    <div class="float-wrap">
      <div class="row">
        <div class="slider">
          <div class="logo">
            <img src="/assets/img/login-logo.png" alt="Picup" />
            <h2>Enterprise</h2>
          </div>
          <div class="logo" *ngIf="logoUrl">
            <img [attr.src]="logoUrl" alt="Picup" />
          </div>
        </div>
        <div class="form">
          <div class="form-container">
            <ng-container *ngIf="!resetPassword">
              <h3>
                <span [ngClass]="{ active: !signUp }">{{signUp ? 'Sign Up' : 'Sign In'}}</span>
              </h3>
              <ng-container *ngIf="!signUp">
                <app-login-main 
                  (loginData)="attemptLogin($event)"
                  (resendOtp)="resendOtpLogin($event)"
                  (forgotPassword)="forgotPassword()"
                  (sendResetPasswordEmail)="forgotPasswordReset($event)"
                  ></app-login-main>
              </ng-container>
              <ng-container *ngIf="signUp">
                <app-sign-up 
                  (signUp)="signUpEvent($event)" 
                  [prefilledEmail]="prefilledEmail">
                </app-sign-up>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="resetPassword">
              <app-forgot-password
              (goBack)="returnToLogin()"
              (sendReset)="forgotPasswordReset($event)"
              ></app-forgot-password>
            </ng-container>
            <p class="auth-error" *ngIf="loginError">{{ loginError }}</p>
            <p class="reset-message" *ngIf="resetMessage">{{ resetMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>