<app-modal [open]="true" [actions]="disableSave ? loadingActions : actions" [size]="ModalSizes.Large"
  [highlightPrimaryAction]="true" (handleAction)="handleAction($event)">
  <heading 
    [icon]="iconTypes.Bucket" 
    [color]="uiColors.Blue">
    <h4 class="heading-title">{{ intention === 'edit' ? 'Update Order' : 'Add New Order to Bucket' }}</h4>
  </heading>
  <div class="modal-body" *ngIf="intention">
    <app-grid-container 
      [padding]="'1rem 0'" 
      [padding]="'1rem 0'" 
      [columns]="'repeat(2, 1fr)'" 
      [colGap]="'2rem'"
      [theme]="uiThemes.Light">
      <div>
        <label>Visit Type</label>
        <app-basic-button 
          [title]="shipment.visit_type" 
          [type]="buttonTypes.Primary"
          (click)="changeVisitType(shipment.visit_type)">
        </app-basic-button>
      </div>
    </app-grid-container>
    <app-grid-container 
      *ngIf="environment.appVersion === 2"
      [padding]="'1rem 0'" 
      [padding]="'1rem 0'"
      [columns]="'repeat(2, 1fr)'" 
      [colGap]="'2rem'" 
      [theme]="uiThemes.Light">
      <input-field 
        [disabled]="intention !== 'add'" 
        [type]="'text'"
        (valueChanged)="shipment.business_reference = $event" 
        [autoComplete]="'new-name'"
        [value]="shipment.business_reference" 
        [label]="'Business Reference *'"
        [isInvalid]="!shipment.business_reference && displayInvalid">
      </input-field>
      <input-field 
        *ngIf="consignmentDetails?.useCustomConsignments; else showDropdown" 
        [disabled]="environment.envName === 'onthedot'" 
        [type]="'text'"
        (valueChanged)="shipment.consignment = $event.trim()" 
        [autoComplete]="'new-name'" 
        [value]="shipment.consignment"
        [label]="'Consignment'" 
        [isInvalid]="!shipment.consignment && displayInvalid">
      </input-field>
      <ng-template #showDropdown>
        <app-dropdown 
        [label]="'Consignment'" 
        [dropdownTheme]="'light'"
        [activeItem]="shipment.consignment ? shipment.consignment : 'No consignment'"
      >
        <div dropdownRef>
          <a class="dropdown-item" (click)="shipment.consignment = null">No consignment</a>
          <a
            *ngFor="let value of consignmentDetails?.consignmentValues"
            class="dropdown-item"
            (click)="shipment.consignment = value"
            >{{ value }}</a
          >
        </div>
      </app-dropdown>
      </ng-template>
    </app-grid-container>

    <app-grid-container 
      *ngIf="environment.appVersion === 1" 
      [padding]="'1rem 0'" 
      [padding]="'1rem 0'"
      [columns]="'repeat(2, 1fr)'" 
      [colGap]="'2rem'" 
      [theme]="uiThemes.Light">
      <app-places-autocomplete 
        [label]="intention === 'edit' ? 'Update Address' : 'Address'"
        (placesResponse)="onPlacesChange($event)"
        [value]="shipment.address?.geocoded_address ? shipment.address?.geocoded_address : shipment.address?.formatted_address ? shipment.address?.formatted_address : shipment.address.client_address"
        [placeholder]="''" [autoComplete]="'new-name'"
        [isInvalid]="!shipment.address.geocoded_address && !shipment.address.latitude && displayInvalid">
      </app-places-autocomplete>
      <input-field 
        [disabled]="environment.envName === 'onthedot'" 
        [type]="'text'"
        (valueChanged)="shipment.consignment = $event.trim()" 
        [autoComplete]="'new-name'" 
        [value]="shipment.consignment"
        [label]="'Consignment'" 
        [isInvalid]="!shipment.consignment && displayInvalid">
      </input-field>
    </app-grid-container>
    <app-grid-container 
      *ngIf="environment.appVersion === 2" 
      [padding]="'1rem 0'" 
      [padding]="'1rem 0'"
      [columns]="'repeat(2, 1fr)'"
      [colGap]="'2rem'" 
      [theme]="uiThemes.Light">
      <app-places-autocomplete 
        [label]="intention === 'edit' ? 'Update Address' : 'Address *'"
        (placesResponse)="onPlacesChange($event)"
        [value]="shipment.address?.geocoded_address ? 
          shipment.address?.geocoded_address : 
          shipment.address?.formatted_address ? shipment.address?.formatted_address : 
          shipment.address.client_address"
        [placeholder]="''" [autoComplete]="'new-name'"
        [isInvalid]="!shipment.address.geocoded_address && !shipment.address.latitude && displayInvalid">
      </app-places-autocomplete>
      <app-autocomplete *ngIf="environment.appVersion === 2" 
        class="addressBook" 
        [placeholder]="'Start Typing...'"
        [sourceData]="addresses" 
        [label]="'Or Select From Address book'"
        (valueChanged)="onAddressBookPlace($event)"
        (keywordChanged)="getAddressesFromAddressBook($event)"
        [value]="shipment.contact_description">
      </app-autocomplete>
    </app-grid-container>
    <app-grid-container 
      *ngIf="manual_entry_required" 
      [padding]="'1rem 0'" 
      [padding]="'1rem 0'"
      [columns]="'repeat(2, 1fr)'" 
      [colGap]="'2rem'">
      <input-field 
        [type]="'text'" 
        [labelTheme]="'grey'" 
        (valueChanged)="shipment.address.suburb = $event"
        [autoComplete]="'off'" 
        [value]="shipment.address.suburb" 
        [label]="'Suburb'" 
        [required]="true"
        [isInvalid]="!shipment.address.suburb">
      </input-field>
      <input-field 
        [type]="'text'" 
        [labelTheme]="'grey'" 
        (valueChanged)="shipment.address.postal_code = $event"
        [autoComplete]="'off'" 
        [value]="shipment.address.postal_code" 
        [label]="'Postal Code'" 
        [required]="true"
        [isInvalid]="!shipment.address.postal_code">
      </input-field>
    </app-grid-container>
    <app-grid-container 
      [padding]="'1rem 0'" 
      [padding]="'1rem 0'" 
      [columns]="'repeat(2, 1fr)'" 
      [colGap]="'2rem'">
      <input-field 
        [type]="'text'" 
        [labelTheme]="'grey'" 
        (valueChanged)="shipment.address.unit_or_floor = $event"
        [autoComplete]="'off'" 
        [value]="shipment.address.unit_or_floor" 
        [label]="'Unit No.'">
      </input-field>
      <input-field [type]="'text'" 
        [labelTheme]="'grey'" 
        (valueChanged)="shipment.address.building_or_complex_name = $event"
        [autoComplete]="'off'" [value]="shipment.address.building_or_complex_name" 
        [label]="'Building or Complex'">
      </input-field>
    </app-grid-container>
    <app-grid-container 
      [padding]="'1rem 0'" 
      [columns]="'repeat(3, 1fr)'" 
      [colGap]="'2rem'" 
      [rowGap]="'1rem'"
      [theme]="uiThemes.Light">
      <input-field 
        [type]="'text'" 
        (valueChanged)="shipment.contact.customer_name = $event" 
        [autoComplete]="'new-name'"
        [value]="shipment.contact.customer_name" [label]="'Name *'"
        [isInvalid]="!shipment.contact.customer_name && displayInvalid">
      </input-field>
      <input-field 
        [type]="'tel'" 
        (valueChanged)="shipment.contact.customer_phone = $event"
        [value]="shipment.contact.customer_phone" 
        [label]="'Phone *'"
        [isInvalid]="!shipment.contact.customer_phone && displayInvalid" 
        [autoComplete]="'none'">
      </input-field>
      <input-field 
        [autoComplete]="'none'" 
        [type]="'text'" 
        (valueChanged)="shipment.contact.email_address = $event"
        [value]="shipment.contact.email_address" 
        [label]="'Email *'"
        [isInvalid]="!shipment.contact.email_address && displayInvalid">
      </input-field>
      <input-field 
        [type]="'text'" 
        (valueChanged)="shipment.contact.delivery_window_start = $event"
        (actionClicked)="removeDate('delivery_window_start')" 
        [placeholder]="'Auto'"
        [svgActionIcon]="shipment.contact.delivery_window_start ? iconTypes.Cross + '.svg' : null"
        [value]="shipment.contact.delivery_window_start" [label]="'Delivery Window Start (Eg. 08:00)'"
        [isInvalid]="!shipment.contact.delivery_window_start && displayInvalid" [autoComplete]="'new-name'">
      </input-field>
      <input-field [type]="'text'" (valueChanged)="shipment.contact.delivery_window_end = $event"
        (actionClicked)="removeDate('delivery_window_end')" [placeholder]="'Auto'"
        [svgActionIcon]="shipment.contact.delivery_window_end ? iconTypes.Cross + '.svg' : null"
        [value]="shipment.contact.delivery_window_end" [label]="'Delivery Window End (Eg. 08:30)'"
        [isInvalid]="!shipment.contact.delivery_window_end && displayInvalid" [autoComplete]="'none'">
      </input-field>
      <input-field [autoComplete]="'none'" [type]="'text'"
        (valueChanged)="(shipment.contact.duration_at_stop_minutes = +$event)"
        [value]="shipment.contact.duration_at_stop_minutes?.toString()" 
        [label]="'Duration at Stop'"
        [isInvalid]="!shipment.contact.duration_at_stop_minutes && displayInvalid">
      </input-field>
    </app-grid-container>

    <app-grid-container 
      [padding]="'1rem 0'" 
      [columns]="'repeat(1, 1fr)'" 
      [colGap]="'2rem'" 
      [rowGap]="'1rem'"
      [theme]="uiThemes.Light">
      <input-field [autoComplete]="'off'" [type]="'text'"
        (valueChanged)="shipment.contact.special_instructions = $event" [value]="shipment.contact.special_instructions"
        [label]="'Special Instructions'"></input-field>
      <div>
        <label *ngIf="!addressBookUsed && intention !== 'edit'" class="saveAddress">
          Save Address:
          <input type="checkbox" (change)="shipment.save = !shipment.save" [checked]="shipment.save" /></label>

        <app-grid-container 
          *ngIf="shipment.save" 
          [columns]="'repeat(3, 1fr)'" 
          [colGap]="'2rem'"
          [theme]="uiThemes.Light">
          <input-field [placeholder]="'Description'" [autoComplete]="'none'" [type]="'text'"
            (valueChanged)="shipment['contact_description'] = $event"
            [value]="shipment.contact_description ? shipment.contact_description : shipment.contact.customer_name">
          </input-field>
        </app-grid-container>
      </div>
    </app-grid-container>

    <app-grid-container [padding]="'1rem 0'" [columns]="'repeat(1, 1fr)'" [padding]="'1rem 0rem'" [colGap]="'2rem'"
      [theme]="uiThemes.Light" *ngIf="shipment.business_reference">
      <div class="parcels">
        <app-flex-container class="actions" [theme]="uiThemes.Light" [justifyContent]="justifyContent.SpaceBetween">
          <label>Click the correct parcel Size to add it to the order</label>
          <div>
            <app-basic-button 
              [title]="'Clear All'"
              [type]="buttonTypes.Primary" 
              (click)="clearParcels()"
              [disabled]="shipment.parcels?.length === 0">
            </app-basic-button>
            <app-basic-button *ngIf="environment.appVersion === 2"
              [title]="shipment.contact.includeParcelAmounts ? 'Remove Payments' : 'Add Payment'"
              [type]="shipment.contact.includeParcelAmounts ? buttonTypes.Warning : buttonTypes.Highlight" 
              (click)="clearParcelValues(); shipment.contact.includeParcelAmounts = !shipment.contact.includeParcelAmounts">
            </app-basic-button>
          </div>
        </app-flex-container>

        <app-flex-container [theme]="uiThemes.Light">
          <div class="selectors parcel-selector" *ngFor="let parcelSize of parcelSizes">
            <img [src]="'/assets/img/' + parcelSize + '.svg'" [autoComplete]="'new-name'"
              [alt]="parcelSize + ' icon'" (click)="addParcel(parcelSize)"/>
              <div class="icons-flex">
                <mat-form-field style="width: 100px; padding-top: 5px" appearance="outline">
                  <input matInput type="number" [defaultValue]="0" #number va>
                  <button matSuffix mat-icon-button matTooltip="Add parcels" (click)="bulkAddParcels(number.value, parcelSize)">
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-form-field>
              </div>
          </div>

        </app-flex-container>
      </div>
      <app-grid-container 
        [ngStyle]="" class="parcel-editor-container" 
        [columns]="'repeat(2, 1fr)'" 
        [colGap]="'2rem'"
        [rowGap]="'1rem'" 
        [padding]="'2rem 0 0'" 
        [theme]="uiThemes.Light">
        <div class="parcel-editor" 
        *ngFor="let parcel of shipment.parcels; let i = index">
          <app-grid-container 
            class="parcel-editor-container"
            [columns]="'repeat(' + (shipment.contact.includeParcelAmounts || parcel.parcel_value ? 2 : 1) + ', 1fr)'"
            [colGap]="'0.5rem'" 
            [rowGap]="'1rem'" 
            [padding]="'1rem 0 0'">
            <input-field 
              [isInvalid]="!parcel.parcel_reference && displayInvalid"
              [svgActionIcon]="iconTypes.Cross + '.svg'" 
              [placeholder]="'Parcel Reference'" 
              [type]="'text'"
              (actionClicked)="removeParcel(i)" 
              (valueChanged)="parcel.parcel_reference = $event"
              [value]="parcel.parcel_reference" 
              [label]="parcel.size">
            </input-field>
            <div class="parcel-amounts" *ngIf="shipment.contact.includeParcelAmounts || parcel.parcel_value">
              <label>Amount Payable</label>
              <input-field *ngIf="shipment.contact.includeParcelAmounts || parcel.parcel_value"
                [isInvalid]="!parcel.parcel_value && displayInvalid" 
                [placeholder]="'Parcel Value'" 
                [type]="'number'"
                (valueChanged)="parcel.parcel_value = +$event; updatePaymentsTotal()" 
                [value]="parcel.parcel_value?.toString()">
              </input-field>
            </div>
          </app-grid-container>
        </div>
      </app-grid-container>
    </app-grid-container>

    <app-flex-container *ngIf="+shipment.contact.paymentsTotal > 0" [justifyContent]="justifyContent.FlexStart"
      [padding]="'2rem 0 0'">
      <h4 class="payments-total">
        {{ 'Payments Total: ' + environment.currency_symbol + shipment.contact.paymentsTotal }}
      </h4>
    </app-flex-container>

    <app-flex-container [theme]="uiThemes.Light" [padding]="'0'" class="cta">
      <div class="error" *ngIf="shipment?.parcels?.length === 0 && displayInvalid">
        <h4>Please select a parcel</h4>
      </div>
    </app-flex-container>
  </div>
</app-modal>